<template>
  <q-card :style="{ height: `${sHeight}px` }" class="column">
    <q-card-section class="bg-primary">
      <div class="row items-center">
        <q-btn
          flat
          color="white"
          size="sm"
          icon="arrow_back_ios"
          @click="router.go(-1)"
        ></q-btn>
        <div>
          <div class="text-white text-bold">Checkout</div>
          <div class="text-caption text-white">
            Pesanan Anda Hampir Selesai!
          </div>
        </div>
      </div>
      <q-markup-table
        flat
        wrap-cells
        separator="none"
        class="bg-transparent text-white q-mt-md"
      >
        <tr>
          <td>Nama</td>
          <td class="text-capitalize text-bold text-right">
            {{ data.order.nama }}
          </td>
        </tr>
        <tr>
          <td>No.HP</td>
          <td class="text-bold text-right">
            {{ data.order.hp.match(/.{1,4}/g).join("-") }}
          </td>
        </tr>
        <tr>
          <td>Kelompok</td>
          <td class="text-bold text-right">{{ data.order.kelompok }}</td>
        </tr>
      </q-markup-table>
    </q-card-section>
    <q-card-section class="text-bold my-bg q-px-lg"
      >Detail Pesanan</q-card-section
    >
    <q-scroll-area class="my-bg l-grow">
      <q-list class="bg-transparent q-px-lg q-pb-lg q-gutter-y-md" separator>
        <q-card v-if="data.order.beras > 0">
          <q-card-section class="row q-gutter-x-sm">
            <q-img
              :src="`./img/beras.jpeg`"
              fit="container"
              style="width: 50px; height: 50px"
            ></q-img>
            <div class="column q-gutter-y-xs">
              <div class="text-bold">{{ data.beras.desc }}</div>
              <div>{{ data.order.beras }} x {{ rupiah(data.beras.price) }}</div>
            </div>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section class="row justify-between">
            <div>Total Harga</div>
            <div class="text-bold">
              {{ rupiah(data.beras.price * data.order.beras) }}
            </div>
          </q-card-section>
        </q-card>
        <q-card v-if="data.order.minyak > 0">
          <q-card-section class="row q-gutter-x-sm">
            <q-img
              :src="`./img/minyak.jpg`"
              fit="container"
              style="width: 50px; height: 50px"
            ></q-img>
            <div class="column q-gutter-y-xs">
              <div class="text-bold">{{ data.minyak.desc }}</div>
              <div>
                {{ data.order.minyak }} x {{ rupiah(data.minyak.price) }}
              </div>
            </div>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section class="row justify-between">
            <div>Total Harga</div>
            <div class="text-bold">
              {{ rupiah(data.minyak.price * data.order.minyak) }}
            </div>
          </q-card-section>
        </q-card>
      </q-list>
      <q-inner-loading :showing="isLoadingData">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </q-scroll-area>
    <q-card-section class="q-pa-none">
      <q-card-section class="bg-white q-gutter-y-md shadow-up-2">
        <div
          class="text-negative q-mt-none"
          style="word-wrap: break-word !important"
        >
          <q-icon size="xs" name="new_releases" color="negative"></q-icon>
          <span v-if="data.order.pay_method == 'TF'">
            Pesanan Diproses Setelah Bukti Transfer Di Upload!
          </span>
          <span v-else-if="data.order.pay_method == 'CASH'">
            Selesaikan Pembayaran Sebelum
            {{ moment(data.period.ditutup).format("DD MMM YYYY, HH:mm") }}
          </span>
          <span v-else> Silahkan Pilih Metode Pembayaran </span>
        </div>
        <q-list
          class="my-bg cursor-pointer"
          @click="
            data.rekening.nama_rek
              ? (dialogMethod = true)
              : (dialogMethod = false)
          "
        >
          <div class="row">
            <div
              class="l-grow items-center"
              v-if="data.order.pay_method == 'TF'"
            >
              <q-item>
                <q-item-section side class="text-grey">
                  No. Rek
                </q-item-section>
                <q-item-section>
                  <div class="text-right">
                    <span class="text-primary">
                      ({{ data.rekening.bank_rek }})
                    </span>
                    <span>
                      {{ data.rekening.no_rek }}
                    </span>
                  </div>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section side class="text-grey"
                  >Atas Nama
                </q-item-section>
                <q-item-section class="text-right">
                  <span>
                    {{ data.rekening.nama_rek }}
                  </span>
                </q-item-section>
              </q-item>
            </div>
            <div class="l-grow" v-else-if="data.order.pay_method == 'CASH'">
              <q-item>
                <q-item-section side class="text-grey">
                  Penanggung Jawab
                </q-item-section>
                <q-item-section class="text-bold text-right">
                  Bpk. Anggit
                </q-item-section>
              </q-item>
            </div>
            <div class="l-grow text-bold q-pa-md" v-else>
              Pilih Metode Pembayaran
            </div>
            <q-btn flat dense icon="arrow_forward_ios"></q-btn>
          </div>
        </q-list>
        <div class="row justify-between items-center">
          <div>
            <div>Total Harga</div>
            <div class="f14 text-bold">
              {{
                rupiah(
                  data.minyak.price * data.order.minyak +
                    data.beras.price * data.order.beras
                )
              }}
            </div>
          </div>
          <q-btn
            unelevated
            label="Upload Bukti"
            color="primary"
            :disable="isLoadingData || !data.order.pay_method"
            v-if="data.order.pay_method == 'TF'"
            @click="dialogUpload = true"
          ></q-btn>
          <q-btn
            unelevated
            label="PESAN BARANG"
            color="primary"
            :disable="isLoadingData || !data.order.pay_method"
            v-else
            @click="waitThis(sendOrder)"
          ></q-btn>
        </div>
      </q-card-section>
    </q-card-section>

    <q-dialog persistent v-model="dialogUpload">
      <q-card style="width: 300px">
        <q-card-section class="row items-center justify-between">
          <div class="text-h6">Upload Bukti Transfer</div>
          <q-btn flat icon="close" v-close-popup></q-btn>
        </q-card-section>
        <q-card-section>
          <q-file
            outlined
            v-model="file"
            accept=".jpg, image/*"
            label="Pilih File"
          >
            <template v-slot:prepend>
              <q-icon name="attach_file" />
            </template>
          </q-file>
        </q-card-section>
        <q-card-actions align="stretch">
          <q-btn
            unelevated
            color="primary"
            class="full-width"
            label="UNGGAH BUKTI"
            :disable="!file"
            @click="waitThis(sendOrder)"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogMethod">
      <q-card>
        <q-card-section class="row justify-between items-center">
          <a class="text-weight-bold">Pilih Metode Pembayaran</a>
          <q-btn
            flat
            dense
            icon="close"
            size="sm"
            color="primary"
            v-close-popup
          ></q-btn>
        </q-card-section>
        <q-card-section class="my-bg">
          <q-list separator>
            <q-item
              class="bg-white"
              @click="data.order.pay_method = 'CASH'"
              clickable
              v-ripple
              v-close-popup
            >
              <q-item-section avatar>
                <q-icon color="primary" name="payments"> </q-icon>
              </q-item-section>

              <q-item-section>
                <q-item-label class="text-bold">CASH</q-item-label>
                <q-item-label caption
                  >Pembayaran Dilakukan Kepada Bpk. Anggit sebelum periode
                  ditutup ({{
                    moment(data.period.ditutup).format("DD-MMM-YYYY, HH:mm")
                  }})</q-item-label
                >
              </q-item-section>

              <q-item-section side>
                <q-btn flat dense icon="arrow_forward_ios"></q-btn>
              </q-item-section>
            </q-item>
            <q-item
              class="bg-white"
              @click="data.order.pay_method = 'TF'"
              clickable
              v-ripple
              v-close-popup
            >
              <q-item-section avatar>
                <q-icon color="primary" name="account_balance"> </q-icon>
              </q-item-section>

              <q-item-section>
                <q-item-label>
                  <span class="text-bold"> Transfer </span>
                  <span class="text-primary"
                    >({{ data.rekening.bank_rek }})</span
                  ></q-item-label
                >
                <q-item-label caption
                  >Pesanan Diproses Setelah Bukti Transfer Di
                  Upload</q-item-label
                >
              </q-item-section>

              <q-item-section side>
                <q-btn flat dense icon="arrow_forward_ios"></q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogSuccess" persistent>
      <q-card>
        <q-img
          :src="`./img/success.gif`"
          fit="container"
          style="width: 250px; height: 200px"
        ></q-img>
        <q-card-section class="text-center text-bold"
          >Pesanan Berhasil Dibuat!</q-card-section
        >
        <q-card-actions align="stretch">
          <q-btn
            unelevated
            class="full-width"
            color="primary"
            label="Kembali ke Halaman Utama"
            to="/finish-payment"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import useBasic from "@/composables/useBasic";
import dialogPengambilan from "../components/DialogPengambilan.vue";
export default {
  components: [dialogPengambilan],
  setup(props, context) {
    const {
      reactive,
      ref,
      onMounted,
      route,
      router,
      loadThis,
      isLoadingData,
      $http,
      moment,
      waitThis,
      $q,
      sHeight,
      thousand,
      rupiah,
    } = useBasic();

    let data = reactive({
      beras: {
        price: 0,
        desc: "Beras Sania Premium 5 KG",
      },
      minyak: {
        price: 0,
        desc: "Minyak Goreng Sania 2 L",
      },

      order: {
        nama: "-",
        hp: "-",
        kelompok: "-",
        beras: 0,
        minyak: 0,
        file_transfer: null,
        pay_method: null,
      },

      period: {},

      price: {},

      rekening: {},
    });

    onMounted(() => {
      loadThis(getData);
    });

    let getData = async () => {
      await getPrice();
      await getPeriod();
      await getrek();
    };

    let getPeriod = async () => {
      let resp = await $http.get(`/active-period`);
      data.period = resp.data[0];
    };

    let getqty = () => {
      data.order.minyak = parseInt(localStorage.getItem("minyak"));
      data.order.beras = parseInt(localStorage.getItem("beras"));
    };

    let getrek = async () => {
      let resp = await $http.get("/rek");
      data.rekening = resp.data;
    };

    let getUser = () => {
      data.order.nama = route.meta.user.nama;
      data.order.hp = route.meta.user.hp;
      data.order.kelompok = route.meta.user.kelompok;
    };

    let getPrice = async () => {
      let resp = await $http.get("/price");
      data.price = resp.data;

      getUser();
      getqty();

      if (data.order.minyak + data.order.beras >= 10) {
        data.minyak.price = resp.data.minyak_agen;
        data.beras.price = resp.data.beras_agen;
      } else {
        data.minyak.price = resp.data.minyak;
        data.beras.price = resp.data.beras;
      }
    };

    let file = ref(null);

    let sendOrder = async () => {
      try {
        let payload = JSON.parse(JSON.stringify(data.order));

        if (payload.pay_method == "TF") {
          const d = new Date();

          let newname = `${payload.nama}_${payload.beras}${
            payload.minyak
          }_${moment().format("DDMMYYHHmmss")}_${d.getTime()}`;
          let ext = file.value.name.split(".")[1];
          newname = `${newname}.${ext}`;

          var formData = new FormData();
          formData.append("file", file.value);
          formData.append("nama", newname);

          await $http.post("/upload.php", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          payload.file_transfer = newname;
        } else {
          payload.file_transfer = "";
        }

        payload.id_periode = localStorage.getItem("period-id");
        await $http.post("/order", payload);

        localStorage.removeItem("beras");
        localStorage.removeItem("minyak");
        dialogSuccess.value = true;
        // context.emit("showCaraAmbil", true);
      } catch (err) {
        $q.notify({
          message:
            "Pesanan gagal dibuat! Silahkan upload bukti pembayaran anda kembali!",
          color: "negative",
        });
      }
    };

    let dialogUpload = ref(false);
    let dialogMethod = ref(false);
    let dialogSuccess = ref(false);

    return {
      data,
      dialogUpload,
      isLoadingData,
      sendOrder,
      file,
      sHeight,
      router,
      thousand,
      rupiah,
      waitThis,
      dialogMethod,
      moment,
      dialogSuccess,
    };
  },
};
</script>
